import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import loadable from '@loadable/component';
import { useDispatch, useSelector } from 'react-redux';
import {
  selectShipmentData,
  selectShipmentStatus,
  selectShipmentError,
  fetchShipments,
  clearShipments,
} from '../features/shipment/shipmentSlice';
import { STATUS } from '../../shared/enums';
import { selectAuthData } from '../features/auth/authSlice';
import generateKey from '../../shared/utils/generateKey';
import AutoRefillLogo from './Dashboard/AutoRefillLogo';
import UserMenuMobile from './UserMenuMobile';

const CustomerFallback = loadable(() => import('./CustomerFallback'));
const StandaloneFilters = loadable(() => import('./StandaloneFilters'));
const HistoryItem = loadable(() => import('./HistoryItem'));

/**
 * @param route
 * @returns {JSX.Element}
 * @constructor
 */
const HistoryPageContent = ({ route }) => {
  const dispatch = useDispatch();
  const auth = useSelector(selectAuthData);
  const shipments = useSelector(selectShipmentData);
  const status = useSelector(selectShipmentStatus);
  const error = useSelector(selectShipmentError);
  const [ shipmentsView, setShipmentsView ] = useState([]);

  useEffect(() => () => dispatch(clearShipments()), []);

  useEffect(() => {
    if (auth && status === STATUS.IDLE) dispatch(fetchShipments());
    return null;
  }, [ auth ]);

  const isShipmentsFound = Array.isArray(shipments) && shipments.length;

  const toggleViewDetails = activeIdx => {
    const output = [];
    shipments.forEach((_, index) => {
      if (index === activeIdx) {
        output.push(true);
      } else {
        output.push(false);
      }
    });
    setShipmentsView(output);
  };

  useEffect(() => {
    if (isShipmentsFound) {
      toggleViewDetails(0);
    }
  }, [ shipments ]);

  return (
    <main className='history'>
      <Helmet>
        <title>{route?.meta?.title}</title>
      </Helmet>
      {(!error && [ STATUS.PREFETCHED, STATUS.SUCCEEDED ].includes(status)) && (
        isShipmentsFound ? (
          <div className='history-main'>
            <AutoRefillLogo />
            <UserMenuMobile />
            <h2>Order History</h2>
            <div className='history-items'>
              {shipments
                .map((shipment, idx) => (
                  <HistoryItem
                    key={generateKey('shipment')}
                    shipment={shipment}
                    idx={idx}
                    isDetailsHidden={shipmentsView[idx]}
                    toggleViewDetails={toggleViewDetails}
                  />
                ))}
            </div>
          </div>
        ) : (
          <div className='history-empty'>
            <AutoRefillLogo />
            <UserMenuMobile />
            <CustomerFallback />
            <StandaloneFilters />
          </div>
        )
      )}
    </main>
  );
};

export default HistoryPageContent;
